import { configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import authReducer from './auth/reducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
  },
});

export type IRootState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;
export const useSelector: TypedUseSelectorHook<IRootState> = useReduxSelector;
