import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from '../../redux/rootReducer';

import LoginForm from './components/LoginForm';
import RegistrationForm from './components/RegistrationForm';

import './auth.scss';

export enum Components {
  LOGIN = 'login',
  REGISTRATION = 'registration',
}

export interface IAuthFormProps {
  setComponent: (component: Components) => void;

  goNext: () => void;

  goBack: () => void;
}

interface IComponentData {
  type: string;
  image?: string;
  form: FC<IAuthFormProps>;
}

const authForms: Record<Components, Array<IComponentData>> = {
  [Components.LOGIN]: [{ type: 'blue', form: LoginForm }],
  [Components.REGISTRATION]: [{ type: 'purple', form: RegistrationForm }],
};

export const Auth: FC = () => {
  const [component, setComponent] = useState<Components>(Components.LOGIN);
  const [formKey, setFormKey] = useState<number>(0);

  const navigate = useNavigate();

  const authStore = useSelector((state) => state.auth);

  useEffect(() => {
    if (authStore.user) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setFormKey(0);
  }, [component]);

  const goNext = (): void => {
    setFormKey((prev) => {
      if (authForms[component][prev + 1]) {
        return prev + 1;
      }

      return prev;
    });
  };

  const goBack = (): void => {
    setFormKey((prev) => {
      if (authForms[component][prev - 1]) {
        return prev - 1;
      }

      return prev;
    });
  };

  const componentData = authForms[component][formKey];

  const AuthForm = componentData.form;

  return (
    <section className="auth">
      <div className="auth-main">
        <AuthForm setComponent={setComponent} goNext={goNext} goBack={goBack} />
        <a href="#" target="_blank" className="auth-main__terms">
          Terms of use
        </a>
      </div>
    </section>
  );
};
