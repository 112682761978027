import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import type { IRegisterRequest } from '../../../interfaces/api/auth';

import type { IAuthFormProps } from '../Auth';
import { Components } from '../Auth';
import GInput, { InputTypes } from '../../../components/GInput/GInput';
import GButton, { ButtonColors, ButtonTypes } from '../../../components/GButton/GButton';
import GCheckbox from '../../../components/GCheckbox/GCheckbox';

import { setUser } from '../../../redux/auth/reducer';

import { apiAuth } from '../../../services/api/auth';

const RegistrationForm: FC<IAuthFormProps> = ({ setComponent, goNext }) => {
  const [disabled, setDisabled] = useState<boolean>(true);

  const dispatch = useDispatch();

  const login = useRef<HTMLInputElement>();
  const email = useRef<HTMLInputElement>();
  const password = useRef<HTMLInputElement>();
  const passwordConfirmation = useRef<HTMLInputElement>();
  const ageConfirmation = useRef<HTMLInputElement>();
  const recaptchaStatus = useRef<boolean>(true);
  const recaptchaToken = useRef<string>();

  const onChangeInput = (): void => {
    setDisabled(
      !login.current?.value ||
        !email.current?.value ||
        !password.current?.value ||
        !passwordConfirmation.current?.value ||
        !ageConfirmation.current?.checked ||
        !recaptchaStatus.current,
    );
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      // if (!recaptchaToken.current) {
      //   throw new Error('no token provided');
      // }

      const data: IRegisterRequest = {
        login: login.current.value,
        email: email.current.value,
        password: password.current.value,
        captcha_token: recaptchaToken?.current ?? null,
      };

      const user = await apiAuth.register(data);

      if (user) {
        dispatch(setUser({ id: user.user_id, token: user.token }));

        goNext();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form
      className="auth-form"
      method="POST"
      onSubmit={(event) => {
        event.preventDefault();

        void (async () => {
          await handleSubmit();
        })();
      }}
    >
      <div
        className="auth-form__back"
        onClick={() => {
          setComponent(Components.LOGIN);
        }}
      >
        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.66699 7.00008L0.959885 6.29297L0.252779 7.00008L0.959885 7.70719L1.66699 7.00008ZM17.667 8.00008C18.2193 8.00008 18.667 7.55237 18.667 7.00008C18.667 6.4478 18.2193 6.00008 17.667 6.00008V8.00008ZM6.29322 0.959641L0.959885 6.29297L2.3741 7.70719L7.70743 2.37385L6.29322 0.959641ZM0.959885 7.70719L6.29322 13.0405L7.70743 11.6263L2.3741 6.29297L0.959885 7.70719ZM1.66699 8.00008H17.667V6.00008H1.66699V8.00008Z"
            fill="#288DF5"
          />
        </svg>

        <span>Login to existing account</span>
      </div>

      <div className="auth-form-header">
        <span className="auth-form-header__title">Create an account</span>
        <p className="auth-form-header__description">Please fill in all fields</p>
      </div>

      <div className="auth-form-body">
        <GInput
          reference={login}
          type={InputTypes.TEXT}
          name="login"
          placeholder="Profile name"
          onChange={onChangeInput}
        />

        <GInput
          reference={email}
          type={InputTypes.EMAIL}
          name="email"
          placeholder="Email"
          onChange={onChangeInput}
        />

        <GInput
          reference={password}
          type={InputTypes.PASSWORD}
          name="password"
          placeholder="Password"
          onChange={onChangeInput}
        />

        <GInput
          reference={passwordConfirmation}
          type={InputTypes.PASSWORD}
          name="password_confirmation"
          placeholder="Repeat password"
          onChange={onChangeInput}
        />
      </div>

      {/* <ReCaptchaComponent */}
      {/*  class="auth-form-captcha" */}
      {/*  callback={(state, token) => { */}
      {/*    setDisabled( */}
      {/*      !login.current?.value || */}
      {/*        !email.current?.value || */}
      {/*        !password.current?.value || */}
      {/*        !passwordConfirmation.current?.value || */}
      {/*        !ageConfirmation.current?.checked || */}
      {/*        !state, */}
      {/*    ); */}

      {/*    recaptchaStatus.current = state; */}

      {/*    if (state) { */}
      {/*      recaptchaToken.current = token; */}
      {/*    } else { */}
      {/*      recaptchaToken.current = undefined; */}
      {/*    } */}
      {/*  }} */}
      {/* /> */}

      <div className="auth-form-confirmation">
        <GCheckbox name="age_confirmation" reference={ageConfirmation} onToggle={onChangeInput}>
          <span>
            I am over 18 years old, and I agree with{' '}
            <a href="#" target="_blank" className="link">
              Terms of Use
            </a>
          </span>
        </GCheckbox>
      </div>

      <div className="auth-form-controls auth-form-controls_half">
        <GButton
          title="Create"
          type={ButtonTypes.SUBMIT}
          color={ButtonColors.BLUE}
          bordered={false}
          filled={true}
          disabled={disabled}
        />
      </div>
    </form>
  );
};

export default RegistrationForm;
