import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import type { IAuthFormProps } from '../Auth';
import { Components } from '../Auth';
import GInput, { InputTypes } from '../../../components/GInput/GInput';
import GButton, { ButtonColors, ButtonTypes } from '../../../components/GButton/GButton';

import { setUser } from '../../../redux/auth/reducer';
import { type ILoginRequest } from '../../../interfaces/api/auth';
import { apiAuth } from '../../../services/api/auth';

const LoginForm: FC<IAuthFormProps> = ({ setComponent }) => {
  const [disabled, setDisabled] = useState<boolean>(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const login = useRef<HTMLInputElement>();
  const password = useRef<HTMLInputElement>();
  const recaptchaStatus = useRef<boolean>(true);
  const recaptchaToken = useRef<string>();

  const onChangeInput = (): void => {
    setDisabled(!login.current?.value || !password.current?.value || !recaptchaStatus.current);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      // if (!recaptchaToken.current) {
      //   throw new Error('no token provided');
      // }

      const data: ILoginRequest = {
        login: login.current.value,
        password: password.current.value,
        captcha_token: recaptchaToken?.current ?? null,
      };

      const user = await apiAuth.login(data);

      if (user) {
        dispatch(setUser({ id: user.user_id, token: user.token }));

        navigate('/browse');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form
      className="auth-form"
      action="#"
      method="POST"
      onSubmit={(event) => {
        event.preventDefault();

        void (async () => {
          await handleSubmit();
        })();
      }}
    >
      <div className="auth-form-header">
        <span className="auth-form-header__title">Sign In</span>
        <p className="auth-form-header__description">Please enter your login and password</p>
      </div>

      <div className="auth-form-body">
        <GInput
          reference={login}
          type={InputTypes.TEXT}
          name="login"
          placeholder="Login"
          onChange={onChangeInput}
        />

        <GInput
          reference={password}
          type={InputTypes.PASSWORD}
          name="password"
          placeholder="Password"
          onChange={onChangeInput}
        />
      </div>

      <span className="auth-form__under-body-link link">Forgot password?</span>

      <div className="auth-form-controls">
        <GButton
          title="Sign In"
          type={ButtonTypes.SUBMIT}
          color={ButtonColors.BLUE}
          bordered={false}
          filled={true}
          disabled={disabled}
        />

        <GButton
          title="Sign Up"
          type={ButtonTypes.BUTTON}
          color={ButtonColors.BLUE}
          bordered={true}
          filled={false}
          onClick={() => {
            setComponent(Components.REGISTRATION);
          }}
        />
      </div>
    </form>
  );
};

export default LoginForm;
