import { ApiServiceBase } from '../apiServiceBase';
import {
  type IAuthResponse,
  type ILoginRequest,
  type IRegisterRequest,
} from '../../interfaces/api/auth';
import { type IUserInfo } from '../../interfaces/user';

class ApiAuth extends ApiServiceBase {
  protected baseUrl = 'auth/';

  public login = async (data: ILoginRequest): Promise<IAuthResponse | false> => {
    try {
      const response = await this.post<ILoginRequest, IAuthResponse>('login', data);

      if (response.status === 200) {
        return response.data;
      }

      return false;
    } catch (e) {
      return false;
    }
  };

  public register = async (data: IRegisterRequest): Promise<IAuthResponse | false> => {
    try {
      const response = await this.post<IRegisterRequest, IAuthResponse>('register', data);

      if (response.status === 200) {
        return response.data;
      }

      return false;
    } catch (e) {
      return false;
    }
  };

  public getUser = async (): Promise<IUserInfo> => {
    const response = await this.get<IUserInfo>('user');

    return response.data;
  };
}

export const apiAuth = new ApiAuth();
