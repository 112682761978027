import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { State } from './types';
import { type IUserInfo } from '../../interfaces/user';

const initialState: State = {
  user: JSON.parse(localStorage.getItem('user')) ?? null,
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserInfo>) => {
      if (!action.payload) {
        localStorage.removeItem('user');
        return;
      }

      localStorage.setItem('user', JSON.stringify(action.payload));

      state.user = action.payload;
    },
  },
});

export const { setUser } = authReducer.actions;

export default authReducer.reducer;
