import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './configs/Routes';

function App(): JSX.Element {
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}

export default App;
