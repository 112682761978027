import { type RouteObject } from 'react-router-dom';
import React from 'react';
import ProtectedLayout from '../layouts/ProtectedLayout';
import { Auth } from '../pages/Auth/Auth';
import { Header } from '../components/Header/Header';

const Routes: Array<RouteObject> = [
  {
    path: '/auth',
    element: (
      <>
        <Header />
        <Auth />
      </>
    ),
  },
  {
    path: '/',
    element: <ProtectedLayout />,
    children: [
      {
        path: '/chats',
        element: <>123</>,
      },
    ],
  },
];

export default Routes;
