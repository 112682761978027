import type { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSelector } from '../redux/rootReducer';
import { setUser } from '../redux/auth/reducer';
import { apiAuth } from '../services/api/auth';

const ProtectedLayout: FC = () => {
  const dispatch = useDispatch();

  const authStore = useSelector((state) => state.auth);

  useQuery({
    queryKey: ['auth'],
    queryFn: apiAuth.getUser,
    onSuccess: (user) => {
      dispatch(setUser(user));
    },
    onError: () => {
      dispatch(setUser(null));
      localStorage.removeItem('user');
      window.location.href = '/auth';
    },
    staleTime: 120 * 60 * 1000,
    cacheTime: 120 * 60 * 1000,
    retry: false,
  });

  if (!authStore.user) {
    console.log('move');
    return <Navigate to="/auth" />;
  }

  return <Outlet />;
};

export default ProtectedLayout;
