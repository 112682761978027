import Axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { stringify } from 'qs';
import { type IUserInfo } from '../interfaces/user';

type TAnyObject = Record<string, any>;

interface IApiRouterParams<Params extends string = string, Query extends TAnyObject = TAnyObject> {
  params?: Record<Params, string>;
  query?: Query;
}

const getApiRouter = (route: string, { query, params = {} }: IApiRouterParams = {}): string => {
  let formattedRoute = route;

  for (const paramKey of Object.keys(params)) {
    formattedRoute = formattedRoute.replace(`:${paramKey}`, params[paramKey]);
  }

  if (query) {
    const queryString = stringify(query, { arrayFormat: 'repeat' });
    formattedRoute = `${formattedRoute}?${queryString}`;
  }

  return formattedRoute;
};

export interface IAxiosRequestConfig extends Omit<AxiosRequestConfig, 'params'>, IApiRouterParams {}

export const apiAxiosInstance = Axios.create({
  timeout: 60000 * 2,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

export abstract class ApiServiceBase {
  protected baseUrl = '';
  private readonly axios: AxiosInstance = apiAxiosInstance;
  private readonly domain = 'https://00x.club/api/';

  protected get<Response extends TAnyObject>(
    url = '',
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<AxiosResponse<Response>> {
    return this.axios.get(getApiRouter(this.domain + this.baseUrl + url, { params, query }), {
      ...config,
      headers: {
        ...(config.headers ?? {}),
        Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user') ?? '{}') as IUserInfo)?.token}`,
      },
    });
  }

  protected post<Request extends TAnyObject, Response extends TAnyObject = TAnyObject>(
    url = '',
    data?: Request,
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<AxiosResponse<Response>> {
    return this.axios.post(
      getApiRouter(this.domain + this.baseUrl + url, { params, query }),
      data,
      {
        ...config,
        headers: {
          ...(config.headers ?? {}),
          Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user') ?? '{}') as IUserInfo)?.token}`,
        },
      },
    );
  }
}
