import { type FC } from 'react';
import './Header.scss';

export const Header: FC = () => {
  return (
    <div className="header">
      <div className="header__logo">00x Club</div>
      <div className="header__navigate">
        <span>Browse</span>
        <span>Chats</span>
        <span>FAQ</span>
        <span>Contacts</span>
      </div>
      <div className="header__auth">
        <button>Sign in</button>
        <button>Sign up</button>
      </div>
    </div>
  );
};
